<template>
 <my-slot >
		<div class="my_index_c"  slot="content">
			<h3>{{this.$t('changePhone')}}</h3>
			<div>
				<ul>
					<li>
						<p>{{this.$t('newPhone')}}：</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="userMobile" type="text" :placeholder="this.$t('iptNewPhone')" /></div>
						</div>
						<div class="clear"></div>
					</li>
					<li>
						<p>{{this.$t('testCode')}}：</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="code" type="text" :placeholder="this.$t('inputCode')" />
							<button @click="getCode" v-if="show">{{this.$t('getCode')}}</button><button v-else disabled class="return-code">{{count}}秒后重新获取</button></div>
						</div>
						<div class="clear"></div>
					</li>
					<li><div class="my_index_rt_4"><input name="" type="button" :value="this.$t('sureChange')" @click="confirmChange"/></div></li>
				</ul>
			</div>
		</div>
</my-slot>
</template>

<script>
import MySlot from '@/components/component/MySlot.vue'
import { changePhone } from './model'
import {verifyCode } from '../../register/model'
import { showLayer } from "@/utils/com";
export default {
    data(){
        return {
                userMobile:'',
                code:'',
                show:true,
                disabled:true,
                count:''
        }
    },
    components:{
        MySlot
    },
    methods:{
        getCode(){
           if(this.userMobile !== ''){
                verifyCode({userMobile:this.userMobile,type:2}).then(res =>{
               if(res === 'success') {
					// console.log('获取验证码成功');
					this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
						this.count = TIME_COUNT;
						this.show = false;
						this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
				}
            })
           }
        },
    confirmChange(){
        changePhone({userMobile:this.userMobile,code:this.code}).then(res=>{
            if(res === 'ok'){
				showLayer("success", "修改成功，请重新登录！");
                window.localStorage.clear('sessionId')
                this.$router.push('/login')
            }
        })
    }

    }
}
</script>

<style>
.return-code {
	margin-left: -15px;
}
</style>